import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, notification, Switch } from 'antd';
import axios from 'axios';

function SignUp() {
    const [loading, setLoading] = useState(false);
    const [isYearly, setIsYearly] = useState(true); // Default to yearly
    const navigate = useNavigate();

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const response = await axios.post('https://ec2-51-20-127-69.eu-north-1.compute.amazonaws.com/api/users/create', {
                email: values.email.split(','), // Convert comma-separated emails into an array
                username: values.username.split(','), // Convert comma-separated usernames into an array
                password: values.password,
                phoneNo: values.phoneNo // Include the phone number in the request
            });
            notification.success({
                message: 'Success',
                description: 'Account created successfully!',
            });
            navigate('/login');
        } catch (error) {
            console.log(error)
            notification.error({
                message: 'Error',
                description: error.response?.data?.error || 'Something went wrong!',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
            <h2 className='signup-login-header'><strong>SIGN UP FORM</strong></h2>
            <br />
            <div className='centered-form-container' style={styles.formContainer}>
                <Form className='signup-login-form' name="signup" onFinish={onFinish}>
                    <Form.Item>
                        <label style={{ fontWeight: 'bold' }}>USERNAME(s)</label><br />
                        <p>* If reserving more than 1, separate with a comma. First come first serve</p><br />
                        <Form.Item
                            name="username"
                            noStyle
                            rules={[{ required: true, message: 'Please input your Username!' }]}
                        >
                            <Input className='signup-login-input' placeholder="Username" style={{ width: '100%' }} />
                        </Form.Item>
                    </Form.Item>
                    
                    <Form.Item>
                        <label style={{ fontWeight: 'bold' }}>Password</label><br />
                        <p>* Password is required</p><br />
                        <Form.Item
                            name="password"
                            noStyle
                            rules={[{ required: true, message: 'Please input your Password!' }]}
                        >
                            <Input.Password className='signup-login-input' placeholder="Password" style={{ width: '100%' }} />
                        </Form.Item>
                    </Form.Item>
                    
                    <Form.Item>
                        <label style={{ fontWeight: 'bold' }}>Email Address(es)</label><br />
                        <p>* We will email each 2 weeks before launch to secure the lowest numbered profiles available</p><br />
                        <Form.Item
                            name="email"
                            noStyle
                            rules={[{ required: true, message: 'Please input your Email!' }]}
                        >
                            <Input className='signup-login-input' placeholder="Email" style={{ width: '100%' }} />
                        </Form.Item>
                    </Form.Item>
                    
                    <Form.Item>
                        <label style={{ fontWeight: 'bold' }}>Phone number</label><br />
                        <p>* Of person paying today</p><br />
                        <Form.Item
                            name="phoneNo"
                            noStyle
                            rules={[{ required: isYearly, message: 'Please input your Phone Number!' }]}
                        >
                            <Input className='signup-login-input' placeholder="Phone Number" style={{ width: '100%' }} />
                        </Form.Item>
                    </Form.Item>
                    
                    <Form.Item label="Subscription Type">
                        <Switch
                            checked={isYearly}
                            onChange={(checked) => setIsYearly(checked)}
                            checkedChildren="Yearly"
                            unCheckedChildren="Monthly"
                        />
                    </Form.Item>
                    
                    <Form.Item>
                        <Button className='signup-login-button' type="primary" htmlType="submit" loading={loading}>
                            Sign Up
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            {!loading && (
                <Button className='signup-login-link' type="link" onClick={() => navigate('/login')}>
                    Already have an account? Login
                </Button>
            )}
        </div>
    );
}

const styles = {
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        padding: '20px',
        width: '95%',
        maxWidth: 800, // Adjust maxWidth for better form appearance
    },
};

export default SignUp;
