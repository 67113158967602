import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, notification, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { loginUser } from '../store/action';

function Login() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onFinish = async (values) => {
        setLoading(true);
        try {
            await dispatch(loginUser(values.email, values.password));
            localStorage.setItem('userEmail', values.email);
            notification.success({
                message: 'Success',
                description: 'Logged in successfully!',
            });
            if(values.email?.toLowerCase()==="admin@reeltok.com" ){
                navigate('/admin');
            }else{
                navigate('/');
            }
            
        } catch (error) {
            notification.error({
                message: 'Error',
                description: error.message,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <h2 className='signup-login-header'>Login</h2>
            <br />
            <div className='centered-form-container'>
                {loading ? (
                    <Spin size="large" />
                ) : (
                    <Form className='signup-login-form' name="login" onFinish={onFinish}>
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please input your Email!' }]}
                        >
                            <Input className='signup-login-input' placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your Password!' }]}
                        >
                            <Input.Password className='signup-login-input' placeholder="Password" />
                        </Form.Item>
                        <Form.Item>
                            <Button className='signup-login-button' type="primary" htmlType="submit">
                                Login
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </div>
            {!loading && (
                <Button className='signup-login-link' type="link" onClick={() => navigate('/signup')}>
                    Don't have an account? Signup
                </Button>
            )}
        </div>
    );
}

export default Login;
