import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
          <p>
        ReelTok may collect information from users during registration such as email
        ID’s and birthdays to better provide service to our customers. We reserve the
        right to use this information with our 3rd party partners to provide our users
        the best experience possible. ReelTok may also collect user engagement data
        such as correct and incorrect answers so that we can provide our members
        with the information they need to maximize the learning of their children.
      </p>
      <p>
        When you use a website, mobile application, or other internet service, certain
        internet and electronic network activity information gets created and logged
        automatically. This is also true when you use ReelTok. Here are some of the
        types of information we collect:
      </p>
      <p>
        <strong>Log data.</strong> When you use ReelTok, our servers record information (“log data”),
        including information that your browser automatically sends whenever you
        visit a website, or that your mobile app automatically sends when you’re using
        it. This log data includes your Internet Protocol address (which we use to infer
        your approximate location), the address of and activity on websites you visit
        that incorporate ReelTok features (like the “Save” button—more details below),
        searches, browser type and settings, the date and time of your request, how you
        used ReelTok.
      </p>
      <p>
        <strong>ReelTok, cookie data and device data.</strong>
      </p>
      <p>
        <strong>Cookie data.</strong> We also use “cookies” (small text files sent by your computer each
        time you visit our website, unique to your ReelTok account or your browser) or
        similar technologies to get log data. When we use cookies or other similar
        technologies, we use session cookies (that last until you close your browser) or
        persistent cookies (that last until you or your browser delete them). For
        example, we use cookies to store your language preferences or other settings so
        you don‘t have to set them up every time you visit ReelTok. Some of the cookies
        we use are associated with your ReelTok account (including information about
        you, such as the email address you gave us) and other cookies are not.
      </p>
      <p>
        <strong>Device information.</strong> In addition to log data, we collect information about the
        device you’re using ReelTok on, including the type of device, operating system,
        settings, unique device identifiers and crash data that helps us understand when
        something breaks.
      </p>
      <p>
        <strong>Clickstream data and inferences.</strong> When you’re on ReelTok, we use your activity—
        such as which items you click on—along with information you provided when
        you first signed up. We may also infer information about your education or
        professional experience based on your activity when you link your account to
        accounts you have with third parties like Facebook or Google.
      </p>
      <p>
        We also get information about you and your activity outside ReelTok from our
        affiliates, advertisers, and ReelTok features such as our “Save” button. We may
        use this information to provide discounts and special offers with your unique
        Dragon you create.
      </p>
      <p>
        <strong>What we do with the info we collect.</strong>
      </p>
      <p>
        We’re committed to showing you content that’s relevant, interesting, and personal
        to you. To do that, we use your information to provide and improve your
        experience, including:
      </p>
      <ul>
        <li>Identify you when you use ReelTok.</li>
        <li>Respond to your questions or comments.</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
