import React, { useEffect, useState } from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

const SuccessPage = () => {
  const [showLogout, setShowLogout] = useState(false);
  const userEmail = localStorage.getItem('userEmail');
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  useEffect(() => {
    if (userEmail && userEmail !== 'admin@reeltok.com') {
      setShowLogout(true);
    } else {
      setShowLogout(false);
    }
  }, [userEmail]);
  const handleLogout = () => {
    localStorage.removeItem('userEmail');
    setShowLogout(false); // Hide logout button
    notification.success({
      message: 'Logged out',
      description: 'You have been logged out successfully!',
    });
    navigate('/prices')
  };
  return (
    <div style={{ minHeight: '100vh' }} className="success-container">
      {showLogout && (
        <Button
          type="default"
          icon={<LogoutOutlined />}
          onClick={handleLogout}
          className="logout-button"
        >
          Logout
        </Button>
      )}
      <h1>Payment Successful!</h1>
      <p>Thank you for your purchase. You will receive a confirmation email shortly.</p>
    </div>
  );
};

export default SuccessPage;
