import React, { act, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardNumberElement,CardElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

const stripePromise = loadStripe('pk_test_51O8NCZFZUoQORuXGEoRrHeeNrSK4q0n8CoBGchkyMPcMtHfzhvOgFayxqqSfJmL0qsIo5D0CM5EQ32n9FeToz4kC008u99Wv3U');

const CheckoutForm = ({priceId, priceValue, price}) => {
    const location = useLocation();
    const {  activeTab } = location.state;
    console.log(activeTab,priceId)
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [couponCode, setCouponCode] = useState('');
    const [discount, setDiscount] = useState(0);
    const [finalPrice, setFinalPrice] = useState(priceValue);

    // State variables for form fields
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [cardName, setCardName] = useState('');


    const handleVerifyCoupon = async () => {
        setLoading(true);
    
        // Special cases
        // if (activeTab === "monthly" && couponCode === "Day1Fan") {
        //     setError('The coupon code "Day1Fan" is not valid for monthly plans.');
        //     setLoading(false);
        //     return;
        // }
    
        // if (activeTab === "yearly" && couponCode === "MonthOff") {
        //     setError('The coupon code "MonthOff" is not valid for yearly plans.');
        //     setLoading(false);
        //     return;
        // }
    
        // Existing verification logic
        try {
            const response = await axios.post('https://ec2-51-20-127-69.eu-north-1.compute.amazonaws.com/api/plans/verify', {
                promoCode: couponCode,
                priceId,
            });
    
            if (response.data.valid) {
                const { discountAmount, finalPrice: updatedPrice } = response.data;
                setDiscount(discountAmount);
                setFinalPrice(updatedPrice);
            } else {
                setError('Invalid or expired coupon code.');
            }
        } catch (error) {
            setError(error.response?.data?.error || 'Failed to verify coupon code.');
        } finally {
            setLoading(false);
        }
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            return;
        }

        const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
            billing_details: {
                name: fullName,
                email: email,
                address: {
                    line1: address,
                    city: city,
                    state: state,
                },
            },
        });

        if (stripeError) {
            setError(stripeError.message);
            setLoading(false);
            return;
        }

        const response = await axios.post('https://ec2-51-20-127-69.eu-north-1.compute.amazonaws.com/api/plans/create', {
            email,
            fullName,
            address,
            city,
            state,
            cardName,
            paymentMethodId: paymentMethod.id,
            priceId,
            priceValue: finalPrice,
            returnUrl: 'https://reeltoksocial.com/success',
            failureUrl: 'https://reeltoksocial.com/failure',
            price,
        });

        if (response.data.error) {
            setError(response.data.error);
            navigate('/failure');
        } else if (response.data.requiresAction) {
            const { clientSecret } = response.data;
            const { error } = await stripe.confirmCardPayment(clientSecret);

            if (error) {
                setError(error.message);
                navigate('/failure');
            } else {
                navigate('/success');
            }
        } else {
            navigate('/success');
        }

        setLoading(false);
    };


    return (
        <form onSubmit={handleSubmit} className="checkout-form">
            <div className="billing-section">
                <h2 className="section-title">Billing Address</h2>
                <div className="input-group">
                    <label className="input-label">Full Name</label>
                    <input
                        type="text"
                        className="input-field"
                        placeholder="John M. Doe"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                    />
                </div>
                <div className="input-group">
                    <label className="input-label">Email</label>
                    <input
                        type="email"
                        className="input-field"
                        placeholder="john@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="input-group">
                    <label className="input-label">Address</label>
                    <input
                        type="text"
                        className="input-field"
                        placeholder="542 W. 15th Street"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </div>
                <div className="input-group">
                    <label className="input-label">City</label>
                    <input
                        type="text"
                        className="input-field"
                        placeholder="New York"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                </div>
               
                <div className="input-group">
                    <label className="input-label">State</label>
                    <input
                        type="text"
                        className="input-field"
                        placeholder="NY"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                    />
                </div>
                <div className="input-group">
                    <label className="input-label">Zip Code</label>
                    <input
                        type="text"
                        className="input-field"
                        placeholder="10001"
                        value={zip}
                        onChange={(e) => setZip(e.target.value)}
                    />
                </div>
            </div>

            <div className="payment-section">
                <h2 className="section-title">Payment Details</h2>
                <div className="input-group">
                    <label className="input-label">Coupon Code</label>
                    <input
                        type="text"
                        className="input-field"
                        placeholder="Enter coupon code"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                    />
                    <button type="button" onClick={handleVerifyCoupon} disabled={loading} className="verify-button">
                        {loading ? 'Verifying...' : 'Verify Coupon'}
                    </button>
                </div>
                {discount > 0 ? (
                    <div className="discount-info">
                        <p>Original Price: <s>${priceValue}</s></p>
                        <p>Discounted Price: ${finalPrice.toFixed(2)}</p>
                    </div>
                ):
                <div className="discount-info">
                <p>Price: <b>${priceValue}</b></p>
            </div>
                }
                <div className="input-group">
                    <label className="input-label">Name on Card</label>
                    <input
                        type="text"
                        className="input-field"
                        placeholder="John More Doe"
                        value={cardName}
                        onChange={(e) => setCardName(e.target.value)}
                    />
                </div>
                <div className="input-group">
                    <label className="input-label">Card Number</label>
                    <CardNumberElement className="card-element" />
                </div>
                <div className="input-group">
                    <label className="input-label">Expiration Date</label>
                    <CardExpiryElement className="card-element" />
                </div>
                <div className="input-group">
                    <label className="input-label">CVC</label>
                    <CardCvcElement className="card-element" />
                </div>

                {error && <div className="error-message">{error}</div>}
            </div>



            <div className="btn-section">
                <button type="submit" disabled={!stripe || loading} className="submit-button">
                    {loading ? 'Processing...' : 'Continue to Checkout'}
                </button>
            </div>
        </form>
    );
};

const CheckoutPage = () => {
    const location = useLocation();
    const { priceId, priceValue, price } = location.state;

    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm priceId={priceId} priceValue={priceValue} price={price} />
        </Elements>
    );
};

export default CheckoutPage;
