import React, { useEffect, useState } from 'react';
import { Layout, Menu, Button, notification } from 'antd';
import './PricingPlans.css';
import { useNavigate } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';


const { Content } = Layout;
const productId = 'prod_QhE6ffgbEmHzpE'
const monthlyPlans = [
  {
    name: "Business Basic",
    price: "$14.99/month",
    pricevalue: 14.99,
    priceId: 'price_1PppduFZUoQORuXGrD3Hhhbu',
    features: [
      "50 Reel Gold Coins In Your Wallet Every Month",
      "50 Free Coins to try before you buy",
      "Access to Create Private Groups (up to 20 members)",
      "Up to 40 Second Video Uploads"
    ],
    buttonText: "Subscribe",
    url: "https://buy.stripe.com/test_eVa4iB9032P735K3cc",
    style: "business"
  },
  {
    name: "Free Package",
    price: "Free",
    pricevalue: 0,
    priceId: 'price_1Ppq4rFZUoQORuXGrzbD3TIr',
    features: [
      "All Free Accounts Get 10 RGC Every Month",
      "Access to all our standard social network functions",
      "Up to 15 Second Video Uploads"
    ],
    buttonText: "Subscribe",
    url: "https://buy.stripe.com/test_eVabL3ccf1L30XCbIN",
    style: "free"
  },
  {
    name: "Buy Coins",
    price: "$6.99 + TAX",
    pricevalue: 6.99,
    priceId: 'price_1Ppq4QFZUoQORuXGFtLIS1yN',
    features: [
      "20 additional coins, one time purchase",
      "1 RGC = 4 Delivered Reel Mails"
    ],
    buttonText: "Subscribe",
    url: "https://buy.stripe.com/test_bIYdTb4JNexPeOs148",
    style: "buy"
  },
  {
    name: "Gold",
    price: "$5.99/month",
    pricevalue: 5.99,
    priceId: 'price_1PppfSFZUoQORuXGl02TeiMp',
    features: [
      "20 Free Coins to try before you buy",
      "20 Reel Gold Coins in Your Wallet every month",
      "Access to Create Private Groups (up to 5 members)",
      "Up to 40 Second Video Uploads",
      "Access to New Premium features"
    ],
    buttonText: "Subscribe",
    url: "https://buy.stripe.com/test_00g16p3FJ2P75dS7st",
    style: "gold"
  }
];

const yearlyPlans = [
  {
    name: "Basic",
    price: "$89.99/year",
    originalPrice: "$179.99",
    pricevalue: 179.99,
    priceId: 'price_1PuBcMFZUoQORuXGLbgWKkM0',
    features: [
      "50 Reel Gold Coins In Your Wallet Every Month",
      "50 Free Coins to try before you buy",
      "Access to Create Private Groups (up to 20 members)",
      "Up to 40 Second Video Uploads"
    ],
    buttonText: "Subscribe",
    url: "https://buy.stripe.com/test_fZeaGZccf75n7m0002",
    style: "basic"
  },
  {
    name: "Gold",
    price: "$35.99/year",
    originalPrice: "$71.99",
    pricevalue: 71.99,
    priceId: 'price_1PuBcuFZUoQORuXG7MUSu5iL',
    features: [
      "20 Free Coins to try before you buy",
      "20 Reel Gold Coins in Your Wallet every month",
      "Access to Create Private Groups (up to 5 members)",
      "Up to 40 Second Video Uploads",
      "Access to New Premium features"
    ],
    buttonText: "Subscribe",
    url: "https://buy.stripe.com/test_fZebL3gsv61jayc3cf",
    style: "gold"
  }
];



const PricingPlans = () => {
  // const handleSubscribeClick = (url) => {
  //   window.location.href = url; // Redirects to the Stripe checkout page
  // };
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("monthly");
  const [showLogout, setShowLogout] = useState(false);
  const userEmail = localStorage.getItem('userEmail');
  useEffect(() => {
    if (userEmail && userEmail !== 'admin@reeltok.com') {
      setShowLogout(true);
    } else {
      setShowLogout(false);
    }
  }, [userEmail]);
  const plans = activeTab === "monthly" ? monthlyPlans : yearlyPlans;
  const handleSubscribeClick = (priceId, priceValue, price) => {
    if (!userEmail || userEmail === 'admin@reeltok.com') {
      notification.error({
        message: 'Login Required',
        description: 'Log In or Sign Up First to Join The Fun!',
      });
      return;
    }
    navigate('/checkout', { state: { priceId, priceValue, price,activeTab } });
  };
  const handleLogout = () => {
    localStorage.removeItem('userEmail');
    setShowLogout(false); // Hide logout button
    notification.success({
      message: 'Logged out',
      description: 'You have been logged out successfully!',
    });
  };
  return (
    <Layout style={{ minHeight: '120vh'}} className="layout">
      <Content style={{ padding: '0 50px' }}>
        <div className="site-layout-content">
          
          <div className="tabs">
            <div
              className={`tab ${activeTab === "monthly" ? "active" : ""}`}
              onClick={() => setActiveTab("monthly")}
            >
              Monthly
            </div>
            <div
              className={`tab ${activeTab === "yearly" ? "active" : ""}`}
              onClick={() => setActiveTab("yearly")}
            >
              Yearly (50% Off Before Launch)
            </div>
          </div>
          {showLogout && (
            <Button
              type="default"
              icon={<LogoutOutlined />}
              onClick={handleLogout}
              className="logout-button"
            >
              Logout
            </Button>
          )}
          <h2 className="title">More Options, No Obligations</h2>
          <div className="plans-container">
            {plans.map((plan, index) => (
              <div key={index} className={`plan-card ${plan.style}`}>
                <h3>{plan.name}</h3>
                {activeTab === "yearly" && (
      <h4>
        <span className="original-price"> {plan.price} (<s>{plan.originalPrice}</s>)</span>
       
      </h4>
    )}
    {activeTab !== "yearly" && <h4>{plan.price}</h4>}
                <ul>
                  {plan.features.map((feature, i) => (
                    <li key={i}>{feature}</li>
                  ))}
                </ul>
                <Button onClick={() => handleSubscribeClick(plan.priceId, plan.pricevalue, plan.price)} type="primary" className="subscribe-button">{plan.buttonText}</Button>
              </div>
            ))}
          </div>
          <br></br>
          <h2 className="title">Pay as you go, No long terms contract, Cancel Anytime</h2>
          
        </div>
      </Content>
    </Layout>
  );
};

export default PricingPlans;
