import React, { useState } from 'react';
import { Layout, Menu, Drawer, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import logo from '../assets/logo.png';
import '../PricingPlans.css';
import { Link } from 'react-router-dom';

const { Header, Content } = Layout;

const Headerr = () => {
    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };
    const navigateToExternal = (url) => {
        window.location.href = url;
    };
    return (
        <Layout className="layout">
            <Header className="header">
                <div onClick={() => navigateToExternal('https://reeltoksocial.com/')} className="logo">
                    <img width={200} style={{ marginTop: '15px' }} src={logo} alt="Logo" />
                </div>
                <div className="mobileMenuButton">
                    <Button type="primary" className='btnMenu' icon={<MenuOutlined />} onClick={showDrawer} />
                </div>
                <div className="desktopMenu">
                    <Menu mode="horizontal" defaultSelectedKeys={['1']} className="menu">
                        <Menu.Item key="2" onClick={() => navigateToExternal('https://reeltoksocial.com/business/')} className="menu-item">Business</Menu.Item>
                        <Menu.Item key="1" className="menu-item"><Link to="/prices">Prices</Link></Menu.Item>
                        <Menu.Item key="3" onClick={() => navigateToExternal('https://reeltoksocial.com/contact/')} className="menu-item">Contact</Menu.Item>
                        <Menu.Item key="4" onClick={() => navigateToExternal('https://reeltoksocial.com/about-us/')} className="menu-item">About Us</Menu.Item>
                        <Menu.Item className="menu-item" key="signup"><Link to="/signup">Sign Up</Link></Menu.Item>
                        <Menu.Item className="menu-item" key="login"><Link to="/login">Login</Link></Menu.Item>
                        <Menu.Item key="6" onClick={() => navigateToExternal('https://reeltoksocial.com/faq/')} className="menu-item">FAQ</Menu.Item>
                    </Menu>
                </div>
                <Drawer
                    title="Menu"
                    placement="right"
                    onClose={onClose}
                    visible={visible}
                    className="mobileDrawer"
                >
                    <Menu mode="vertical" defaultSelectedKeys={['1']} className="menu">
                        <Menu.Item key="1" onClick={() => navigateToExternal('https://reeltoksocial.com/business/')}  className="menu-item">Business</Menu.Item>
                        <Menu.Item key="2" onClick={onClose} className="menu-item"><Link to="/prices">Prices</Link></Menu.Item>
                        <Menu.Item key="3" onClick={() => navigateToExternal('https://reeltoksocial.com/contact/')}  className="menu-item">Contact</Menu.Item>
                        <Menu.Item key="4" onClick={() => navigateToExternal('https://reeltoksocial.com/about-us/')}  className="menu-item">About Us</Menu.Item>
                        <Menu.Item onClick={onClose} className="menu-item" key="signup"><Link to="/signup">Sign Up</Link></Menu.Item>
                        <Menu.Item onClick={onClose} className="menu-item" key="login"><Link to="/login">Login</Link></Menu.Item>
                        <Menu.Item onClick={() => navigateToExternal('https://reeltoksocial.com/faq/')}  key="6" className="menu-item">FAQ</Menu.Item>
                    </Menu>
                </Drawer>
            </Header>
        </Layout>
    );
};

export default Headerr;
