// reducers/authReducer.js
import { LOGIN_SUCCESS, FETCH_USER_DETAILS_SUCCESS } from '../action';

const initialState = {
    token: null,
    userDetails: {},
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                token: action.payload,
            };
            case 'LOGOUT':
            return {
                ...initialState, // Reset to initial state on logout
                loading: false,
            };
        case FETCH_USER_DETAILS_SUCCESS:
            return {
                ...state,
                userDetails: action.payload,
            };
        default:
            return state;
    }
};
