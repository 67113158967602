import React, { useEffect, useState } from 'react';
import { Table, Button, notification, Spin, Popconfirm, Input, Layout, Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { EditOutlined, DeleteOutlined, LogoutOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const { Header, Content } = Layout;

const Admin = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editingKey, setEditingKey] = useState('');
    const { userDetails } = useSelector((state) => state.auth);
    const [editableFields, setEditableFields] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Retrieve token from local storage
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (!userDetails?.user?.email.includes('admin@reeltok.com')) {
            notification.error({
                message: 'Access Denied',
                description: 'Please log in as admin to access this panel.',
            });
            return;
        }

        const fetchUsers = async () => {
            try {
                const response = await axios.get('https://ec2-51-20-127-69.eu-north-1.compute.amazonaws.com/api/users/getallusers', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUsers(response.data.users);
            } catch (error) {
                notification.error({
                    message: 'Error',
                    description: 'Failed to fetch users.',
                });
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [token, userDetails]);

    const handleUpdate = async (email) => {
        const updateData = editableFields[email];
        try {
            await axios.put('https://ec2-51-20-127-69.eu-north-1.compute.amazonaws.com/api/users/update', {
                email,
                updateData,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            notification.success({
                message: 'Success',
                description: 'User updated successfully!',
            });
            // Refresh user list after update
            const response = await axios.get('https://ec2-51-20-127-69.eu-north-1.compute.amazonaws.com/api/users/getallusers', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setUsers(response.data.users);
            setEditingKey('');
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to update user.',
            });
        }
    };

    const handleDelete = async (email) => {
        try {
            await axios.delete('https://ec2-51-20-127-69.eu-north-1.compute.amazonaws.com/api/users/delete', {
                headers: { Authorization: `Bearer ${token}` },
                data: { email },
            });
            notification.success({
                message: 'Success',
                description: 'User deleted successfully!',
            });
            const response = await axios.get('https://ec2-51-20-127-69.eu-north-1.compute.amazonaws.com/api/users/getallusers', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setUsers(response.data.users);
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'Failed to delete user.',
            });
        }
    };

    const handleFieldChange = (key, field, value) => {
        setEditableFields(prev => ({
            ...prev,
            [key]: {
                ...prev[key],
                [field]: value,
            },
        }));
    };

    const handleLogout = () => {
        localStorage.clear();
        dispatch({ type: 'LOGOUT' }); // assuming you have a logout action
        navigate('/');
        notification.success({
            message: 'Logged out',
            description: 'You have been logged out successfully.',
        });
    };

    const columns = [
        {
            title: 'Usernames',
            dataIndex: 'username',
            key: 'username',
            render: (text, record) => (
                editingKey === record.email ? (
                    <Input
                        value={editableFields[record.email]?.username || text.join(', ')}
                        onChange={(e) => handleFieldChange(record.email, 'username', e.target.value.split(',').map(v => v.trim()))}
                    />
                ) : (
                    text.join(', ')
                )
            ),
        },
        {
            title: 'Emails',
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => (
                editingKey === record.email ? (
                    <Input
                        value={editableFields[record.email]?.email || text.join(', ')}
                        onChange={(e) => handleFieldChange(record.email, 'email', e.target.value.split(',').map(v => v.trim()))}
                    />
                ) : (
                    text.join(', ')
                )
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <span>
                    {editingKey === record.email ? (
                        <>
                            <Button
                                onClick={() => handleUpdate(record.email)}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={() => setEditingKey('')}
                                style={{ marginLeft: 8 }}
                            >
                                Cancel
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                icon={<EditOutlined />}
                                onClick={() => {
                                    setEditingKey(record.email);
                                    setEditableFields({
                                        ...editableFields,
                                        [record.email]: { username: record.username, email: record.email }
                                    });
                                }}
                            />
                            <Popconfirm
                                title="Are you sure to delete this user?"
                                onConfirm={() => handleDelete(record.email)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    icon={<DeleteOutlined />}
                                    style={{ marginLeft: 8 }}
                                />
                            </Popconfirm>
                        </>
                    )}
                </span>
            ),
        },
    ];

    return (
        <Layout style={{ height: '100vh' }}>
            <Header style={{ backgroundColor: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1>Admin Dashboard</h1>
                <Button icon={<LogoutOutlined />} onClick={handleLogout}>
                    Logout
                </Button>
            </Header>
            <Content style={{ padding: 24 }}>
                {loading ? (
                    <Spin size="large" />
                ) : (
                    <Table
                        dataSource={users}
                        columns={columns}
                        rowKey="email"
                    />
                )}
            </Content>
        </Layout>
    );
};

export default Admin;
