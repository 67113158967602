import React, { useEffect } from 'react';

const FailurePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <div style={{minHeight:'100vh'}} className="failure-container">
      <h1>Payment Failed</h1>
      <p>Unfortunately, your payment could not be processed. Please try again.</p>
    </div>
  );
};

export default FailurePage;
