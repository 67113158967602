import React from 'react';
import Headerr from './components/header.js';
import { Layout } from 'antd';
import './App.css'
import PricingPlans from './PricingPlans.js';
import { Routes, Route } from 'react-router-dom';
import SignUp from './components/signup.js';
import Login from './components/login.js';
import Admin from './components/admin.js';
import CheckoutPage from './components/checkout.js';
import SuccessPage from './components/success.js';
import FailurePage from './components/failure.js';
import Footer from './components/footer.js';
import PrivacyPolicy from './components/privacy.js';
import TermsOfService from './components/terms.js';

const App = () => {
  return (
    <div>
      <Headerr></Headerr>
      <Routes>
        <Route path="/" element={<PricingPlans />} />
        <Route path="/prices" element={<PricingPlans />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/checkout" element={<CheckoutPage/>} />
        <Route path="/success" element={<SuccessPage></SuccessPage>} />
        <Route path="/failure" element={<FailurePage></FailurePage>} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
      </Routes>
      <Footer></Footer>
    </div>
  );
};

export default App;
