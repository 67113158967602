// actions/authActions.js
import axios from 'axios';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS';

export const loginSuccess = (token) => ({
    type: LOGIN_SUCCESS,
    payload: token,
});

export const fetchUserDetailsSuccess = (userDetails) => ({
    type: FETCH_USER_DETAILS_SUCCESS,
    payload: userDetails,
});

export const loginUser = (email, password) => async (dispatch) => {
    try {
        const response = await axios.post('https://ec2-51-20-127-69.eu-north-1.compute.amazonaws.com/api/users/login', {
            email,
            password,
        });
        
        const token = response.data.token;
        console.log(token)
        localStorage.setItem('token', token);
        dispatch(loginSuccess(token));

        const userDetailsResponse = await axios.get('https://ec2-51-20-127-69.eu-north-1.compute.amazonaws.com/api/users/details', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch(fetchUserDetailsSuccess(userDetailsResponse.data));
    } catch (error) {
        throw new Error(error.response?.data?.message || 'Login failed!');
    }
};
